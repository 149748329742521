import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';
import _ from 'lodash';
import { PROJECT_CARBON_FIELD } from '@/shared/constants';

/**
 * get all accomodations
 */
export const getAccomodations = async () => {
	const {
		data: { GetAccomodationList }
	} = await apollo.query({
		query: gql`
            query {
                GetAccomodationList ${columns}
            }
        `,
		fetchPolicy: 'no-cache'
	});

	return GetAccomodationList;
};

/**
 * get one accomodation
 * @param {String, Number} accomodationId
 */
export const getAccomodation = async (accomodationId = 0) => {
	const {
		data: { GetAccomodationWithId }
	} = await apollo.query({
		query: gql`
			query ($accomodationId: ID!) {
				GetAccomodationWithId(AccomodationId: $accomodationId) ${columns}
            }
		`,
		variables: {
			accomodationId: +accomodationId
		},
		fetchPolicy: 'no-cache'
	});

	return GetAccomodationWithId;
};

/**
 * add accomodation
 */
export const addAccomodation = async () => {
	const mutation = gql`
        mutation {
            NewAccomodation ${columns}
        }
    `;
	const {
		data: { NewAccomodation }
	} = await apollo.mutate({
		mutation,
		fetchPolicy: 'no-cache'
	});

	return NewAccomodation;
};

/**
 * update accomodation
 * @param {Number} accomodationId
 * @param {Object} accomodation
 */
export const updateAccomodation = async (accomodationId = 0, accomodation = {}) => {
	const mutation = gql`
        mutation ($accomodationId: ID!, $accomodation: AccomodationInput!) {
            UpdAccomodation(
                AccomodationId: $accomodationId,
                UpdatedAccomodation: $accomodation
            ) ${columns}
        }
    `;
	const {
		data: { UpdAccomodation }
	} = await apollo.mutate({
		mutation,
		variables: {
			accomodationId: +accomodationId,
			accomodation
		},
		fetchPolicy: 'no-cache'
	});

	return UpdAccomodation;
};

/**
 * remove accomodation
 * @param {Number} accomodationId
 */
export const delAccomodation = async (accomodationId = 0) => {
	const mutation = gql`
		mutation ($accomodationId: ID!) {
			DelAccomodation(AccomodationId: $accomodationId) {
				id
			}
		}
	`;
	const {
		data: { DelAccomodation }
	} = await apollo.mutate({
		mutation,
		variables: {
			accomodationId: +accomodationId
		},
		fetchPolicy: 'no-cache'
	});

	return DelAccomodation;
};

/**
 * get all accomodation deliveries
 * @param {Number} accomodationId
 */
export const getDeliveryByAccomodation = async (accomodationId = 0) => {
	const {
		data: { GetAccomodationDeliveryList }
	} = await apollo.query({
		query: gql`
			query ($accomodationId: ID!) {
				GetAccomodationDeliveryList(AccomodationId: $accomodationId) ${deliveryColumns}
            }
		`,
		variables: {
			accomodationId: +accomodationId
		},
		fetchPolicy: 'no-cache'
	});

	return GetAccomodationDeliveryList;
};

/**
 * get all accomodation deliveries
 */
export const getAccomodationDeliveries = async () => {
	const {
		data: { GetAllAccomodationDeliveries }
	} = await apollo.query({
		query: gql`
			query {
				GetAllAccomodationDeliveries ${deliveryColumns}
            }
		`,
		fetchPolicy: 'no-cache'
	});

	return GetAllAccomodationDeliveries;
};

/**
 * remove accomodation delivery
 * @param {Number} accomodationId
 * @param {Number} userId
 * @param {Date} strDate
 * @param {Date} endDate
 * @param {Number} accomodationDeliveryId
 */
export const delAccomodationDelivery = async (accomodationId, userId, accomodationDeliveryId = 0, strDate = null, endDate = null) => {
	const mutation = gql`
		mutation ($accomodationId: ID!, $userId: ID!, $strDate: String, $endDate: String, $accomodationDeliveryId: Int) {
			RemoveUserFromDeliveriesForAccomodation(
                AccomodationId: $accomodationId
                UserId: $userId
                StrDate: $strDate
                EndDate: $endDate
                AccomodationDeliveryId: $accomodationDeliveryId
            ) ${deliveryColumns}
		}
	`;

	let variables = {
		accomodationId: +accomodationId,
		userId: +userId
	};

	if (!_.isNil(strDate) && !_.isNil(endDate)) {
		variables = {
			...variables,
			strDate,
			endDate
		};
	}

	if (!_.isNil(accomodationDeliveryId) && +accomodationDeliveryId > 0) {
		variables = {
			...variables,
			accomodationDeliveryId: +accomodationDeliveryId
		};
	}

	const {
		data: { RemoveUserFromDeliveriesForAccomodation }
	} = await apollo.mutate({
		mutation,
		variables,
		fetchPolicy: 'no-cache'
	});

	return RemoveUserFromDeliveriesForAccomodation;
};

/**
 * update accomodation delivery
 * @param {Number} accomodationId
 * @param {Array} userIds
 * @param {Date} strDate
 * @param {Date} endDate
 * @param {Number} accomodationDeliveryId
 */
export const updateAccomodationDeliveries = async (accomodationId, userIds, strDate = null, endDate = null, confirmation = null, fileName = null) => {
	const mutation = gql`
		mutation ($accomodationId: ID!, $userIds: [Int]!, $strDate: String, $endDate: String, $fileName: String, $confirmation: String) {
			DeliverAccomodationToUsers(
                AccomodationId: $accomodationId
                UserIds: $userIds
                StrDate: $strDate
                EndDate: $endDate
                FileName: $fileName
                Confirmation: $confirmation
            ) ${deliveryColumns}
		}
	`;

	let variables = {
		accomodationId: +accomodationId,
		userIds
	};

	if (!_.isNil(strDate) && !_.isNil(endDate)) {
		variables = {
			...variables,
			strDate,
			endDate
		};
	}

	if (!_.isNil(fileName) && fileName.trim() !== '' && !_.isNil(confirmation) && confirmation.trim() !== '') {
		variables = {
			...variables,
			fileName,
			confirmation
		};
	}

	const {
		data: { DeliverAccomodationToUsers }
	} = await apollo.mutate({
		mutation,
		variables,
		fetchPolicy: 'no-cache'
	});

	return DeliverAccomodationToUsers;
};

/**
 * publish accomodation
 * @param {Number} accomodationId
 */
export const publishAccomodationDeliveries = async (accomodationId = 0, accomodationInput = {}) => {
	const mutation = gql`
		mutation ($accomodationId: ID!, $accomodationInput: AccomodationInput!) {
			PublishAccomodation(AccomodationId: $accomodationId, AccomodationInput: $accomodationInput) ${deliveryColumns}
		}
	`;
	const {
		data: { PublishAccomodation }
	} = await apollo.mutate({
		mutation,
		variables: {
			accomodationId: +accomodationId,
			accomodationInput
		},
		fetchPolicy: 'no-cache'
	});

	return PublishAccomodation;
};

/**
 * publish accomodation
 * @param {Number} accomodationId
 */
export const removeAccomodationDeliveries = async (accommodationDeliveryIdList) => {
	const mutation = gql`
		mutation ($accommodationDeliveryIdList: [AccommodationUserDataInput]!) {
			DelAccommodationDeliveryList(AccommodationDeliveryIdList: $accommodationDeliveryIdList)
		}
	`;
	const {
		data: { DelAccommodationDeliveryList }
	} = await apollo.mutate({
		mutation,
		variables: {
			accommodationDeliveryIdList
		},
		fetchPolicy: 'no-cache'
	});

	return DelAccommodationDeliveryList;
};

/**
 * update accomodation delivery
 * @param {Number} accommodationDeliveryId
 * @param {Object} delivery
 */
export const updateAccomodationDelivery = async (accommodationDeliveryId = 0, delivery = {}) => {
	const mutation = gql`
		mutation ($accommodationDeliveryId: ID!, $delivery: AccomodationDeliveryInput!) {
			UpdAccommodationDelivery(AccommodationDeliveryId: $accommodationDeliveryId, UpdAccommodationDelivery: $delivery)
		}
	`;
	const {
		data: { UpdAccommodationDelivery }
	} = await apollo.mutate({
		mutation,
		variables: {
			accommodationDeliveryId: +accommodationDeliveryId,
			delivery
		},
		fetchPolicy: 'no-cache'
	});

	return UpdAccommodationDelivery;
};

/**
 * get all my accomodation deliveries
 */
export const myAccomodationDeliveries = async () => {
	const {
		data: { MyAccomodationDeliveries }
	} = await apollo.query({
		query: gql`
			query {
				MyAccomodationDeliveries ${myDeliveryColumns}
            }
		`,
		fetchPolicy: 'no-cache'
	});

	return MyAccomodationDeliveries;
};

/**
 * publish single accomodation delivery
 * @param {Number} accommodationDeliveryId
 */
export const publishAccomodationDelivery = async (accommodationDeliveryId = 0, accommodationDelivery = {}) => {
	const mutation = gql`
		mutation ($accommodationDeliveryId: ID!, $accommodationDelivery: AccomodationDeliveryInput!) {
			PublishAccommodationDelivery(AccommodationDeliveryId: $accommodationDeliveryId, AccommodationDelivery: $accommodationDelivery)
		}
	`;
	const {
		data: { PublishAccommodationDelivery }
	} = await apollo.mutate({
		mutation,
		variables: {
			accommodationDeliveryId: +accommodationDeliveryId,
			accommodationDelivery
		},
		fetchPolicy: 'no-cache'
	});

	return PublishAccommodationDelivery;
};

const columns = `{
        id
        reference
        name
        body
        description
        type
        supplierId
        pricePerNight
        cityTax
        vatAmount
        comment
        accessMap
        publishedOn
        publisherId
        toEmail
        toSms
        toWhatsApp
        availableOnline
        coTwoType
        department
        fromAddress
        toAddress
        totalDistance
        specialInstruction
        category
        bookingStatus
        publisher {
            name
            firstName
        }
        supplier {
            id
            addressId
            name
            email
            phone
            vat
            contactName
            address {
                id
                state
                street
                number
                box
                lat
                lng
                city
                country
                countryCode
                countryKey
                zip
            }
        }
        startDate
        endDate
        fileName
        confirmation
        locDepartment {
            id
            value
            message
            category
        }
        accomodationDeliveryList {
            id
            accomodationId
            userId
            status
            statusLastModification
            replyXid
            confirmation
            fileName
            user {
                name
                firstName
            }
            selected
            numberOfPersons
            numberOfNights
        }
        nbBedroom
        addressId
        address {
            id
            state
            street
            number
            box
            lat
            lng
            city
            country
            countryCode
            countryKey
            zip
        }
        carbon {
          ${PROJECT_CARBON_FIELD}
        }
    }
`;

const deliveryColumns = `{
	id
	status
	numberOfNights
	startDate
	endDate
	fileName
	confirmation
	selected
	toEmail
	toSms
	toWhatsApp
	acc {
		id
		reference
		name
		description
		type
		totalDistance
		category
		supplier {
			name
		}
		coTwoType
		nbBedroom
		address {
			id
			state
			street
			number
			box
			lat
			lng
			city
			country
			countryCode
			countryKey
			zip
		}
		specialInstruction
		fromAddress
		toAddress
		bookingStatus
		toEmail
		toSms
		toWhatsApp
		publishedOn
	}
	user {
		id
		name
		firstName
		email
		departmentName
		functionName
		department
	}
}`;

const myDeliveryColumns = `{
	id
	status
	numberOfNights
	startDate
	endDate
	fileName
	confirmation
	selected
	toEmail
	toSms
	toWhatsApp
	acc {
		id
		reference
		name
		description
		type
		totalDistance
		category
		confirmation
		supplier {
			name
			phone
		}
		coTwoType
		nbBedroom
		address {
			id
			state
			street
			number
			box
			lat
			lng
			city
			country
			countryCode
			countryKey
			zip
		}
		specialInstruction
		fromAddress
		toAddress
		bookingStatus
		toEmail
		toSms
		toWhatsApp
		publishedOn
	}
	publisher {
		id
		name
		firstName
		email
		departmentName
		functionName
		department
	}
}`;
